import { simpleDateAtTime } from 'lib/date';
import Activity, { ActivityOptions } from 'models/Activity';
import Group, { GroupOptions } from 'models/Group';
import Profile, { PERMISSIONS } from 'models/Profile';

import Episode from './Episode';
import { EpisodeOptions } from './Episode';
import LocationEpisode, { LocationEpisodeOptions } from './LocationEpisode';
import Patient, { PatientOptions } from './Patient';

export enum EscalationTypes {
  PriorityNote = 'Escalation::PriorityNote',
  Escalation = null!,
}

export enum EscalationOptionTypes {
  Escalation = 'Escalation',
  PriorityNote = 'PriorityNote',
}

export interface EscalationOptions {
  id: string;
  createdAt: string | Date;
  episode: Partial<EpisodeOptions>;
  text: string;
  patient: Partial<PatientOptions>;
  type: EscalationTypes;
  acknowledgedAt: string;
  acknowledgedBy: string;
  acknowledged: boolean;
  activity: Partial<ActivityOptions> | null;
  locationEpisode: Partial<LocationEpisodeOptions>;
  group: Partial<GroupOptions> | null;
}

function getDefaults(): EscalationOptions {
  return {
    id: '',
    createdAt: '',
    episode: {} as EpisodeOptions,
    text: '',
    patient: {} as PatientOptions,
    type: EscalationTypes.Escalation,
    acknowledgedAt: '',
    acknowledgedBy: '',
    acknowledged: false,
    activity: null,
    locationEpisode: {},
    group: null,
  };
}

/**
 * @class Escalation
 * @classdesc Represents an escalation in the system
 * @property {string} id - The escalation's id
 * @property {string | Date} createdAt - The escalation's creation date
 * @property {Episode} episode - The escalation's episode
 * @property {string} text - The escalation's text
 * @property {Patient} patient - The escalation's patient
 * @property {string} type - The escalation's type
 * @property {string} acknowledgedAt - The escalation's acknowledgement date
 * @property {string} acknowledgedBy - The escalation's acknowledgement user
 * @property {boolean} acknowledged - Whether the escalation has been acknowledged
 * @property {Activity} activity - The escalation's activity
 * @property {LocationEpisode} locationEpisode - The escalation's location episode
 * @param {Partial<EscalationOptions>} [options={}]
 * @example const escalation = new Escalation({ id: '123' });
 */
export default class Escalation {
  id: string;
  createdAt: string | Date;
  episode: Episode;
  text: string;
  patient: Patient;
  type: EscalationTypes;
  acknowledgedAt: string;
  acknowledgedBy: string;
  acknowledged: boolean;
  activity: Activity | null;
  locationEpisode: LocationEpisode;
  group: Group | null;

  constructor(options: Partial<EscalationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.createdAt = opts.createdAt;
    this.episode = new Episode(opts.episode);
    this.text = opts.text;
    this.patient = new Patient(opts.patient);
    this.type = opts.type;
    this.acknowledgedAt = opts.acknowledgedAt;
    this.activity = opts.activity ? new Activity(opts.activity) : null;

    this.acknowledgedBy = opts.acknowledgedBy;
    this.acknowledged = opts.acknowledged;
    this.locationEpisode = new LocationEpisode(opts.locationEpisode);
    this.group = opts.group ? new Group(opts.group) : null;
  }

  typeForProfile(profile: Profile) {
    return profile.isManagerFor(this.episode) ? EscalationTypes.PriorityNote : EscalationTypes.Escalation;
  }

  get isDefault() {
    return !this.type;
  }

  get isPriorityNote() {
    return this.type === EscalationTypes.PriorityNote;
  }

  get typeLabel() {
    return this.isPriorityNote ? 'Priority note' : 'Escalation';
  }

  get summary() {
    const { createdBy } = this.activity!;

    return `Added by ${createdBy.fullName} on ${simpleDateAtTime(this.createdAt)}`;
  }

  userCanAcknowledge(profile: Profile) {
    const permission = this.isPriorityNote ? PERMISSIONS.priorityNoteEdit : PERMISSIONS.escalationEdit;

    return profile.has(permission, {
      episode: this.episode,
      locationEpisode: this.locationEpisode,
    });
  }
}
