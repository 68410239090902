import { isEqual } from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';

import colors from 'styles/theme/colors';

type BaseOptionType = {
  label: string;
  value: any;
};

type MiniSelectProps<T extends BaseOptionType, K> = {
  options: T[];
  onChange: (value: T) => void;
  value: K;
  title?: string;
};

function MiniSelect<T extends BaseOptionType, K>({ options, onChange, value, title }: MiniSelectProps<T, K>) {
  return (
    <SelectContainer>
      {title && <Title>{title}</Title>}
      <Select
        options={options}
        value={options.find((option) => isEqual(option.value, value))}
        getOptionValue={(prop) => prop.value}
        getOptionLabel={(prop) => prop.label}
        onChange={onChange}
        styles={customStyles}
        isSearchable={false}
        menuPosition='fixed'
      />
    </SelectContainer>
  );
}

export default MiniSelect;

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 24,
    borderWidth: '1px',
    boxShadow: 'none',
    minHeight: 24,
    fontSize: 12,
    borderColor: colors.black25,
    '&:hover': {
      borderColor: colors.primaryBlue,
      borderWidth: '1px',
    },
    cursor: 'pointer',
    borderSize: 1,
  }),
  dropdownIndicator: (_: any) => ({
    color: colors.black,
    width: '14px',
    marginRight: '4px',
    boxSizing: 'border-box' as any,
    display: 'flex',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    width: 124,
  }),
  input: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 23,
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.primaryBlue : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? colors.primaryBlue : colors.primaryBlue10 },
  }),
};

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const Title = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 8px;
  color: var(--black)
  font-weight: normal;
`;
