import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import Escalation, { EscalationTypes } from 'models/Escalation';
import { colors } from 'styles/theme/colors';
import { Label } from 'styles/typography';
import CircleCheckIcon from 'svg/CircleCheckIcon';

export enum NotificationTypes {
  ACKNOWLEDGED = 'acknowledged',
  NOTE_ADDED = 'note-added',
}

const Messages = {
  [NotificationTypes.ACKNOWLEDGED]: 'Acknowledged and notification sent to {groupName}',
  [NotificationTypes.NOTE_ADDED]: 'Notification sent to {groupName}',
};

type TaskModalNotificationProps = {
  displayNotificationType: NotificationTypes | null;
  recordType: EscalationTypes;
  selectedRecord: Escalation;
  setDisplayNotificationType: (arg: NotificationTypes | null) => void;
};

let notificationTimer,
  visibilityTimer: number = 0;

function TaskModalNotification({
  displayNotificationType,
  recordType,
  selectedRecord,
  setDisplayNotificationType,
}: TaskModalNotificationProps) {
  const [visible, setVisible] = useState(true);

  const patientGroupName =
    recordType === null
      ? selectedRecord.episode.owner.name
      : selectedRecord.episode.rehabInformation.latestRehabFacility.name;
  const message = Messages[displayNotificationType!]?.replace('{groupName}', patientGroupName);

  useEffect(() => clearIntervals, []);

  const clearIntervals = () => {
    clearInterval(notificationTimer);
    clearInterval(visibilityTimer);
    notificationTimer = 0;
    visibilityTimer = 0;
  };

  useEffect(() => {
    if (displayNotificationType) {
      setVisible(true);

      visibilityTimer = window.setTimeout(() => {
        setVisible(false);
      }, 2000);

      notificationTimer = window.setTimeout(() => {
        setDisplayNotificationType(null);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNotificationType]);

  return (
    <Fragment>
      {displayNotificationType && (
        <Container className={visible ? '' : 'hidden'}>
          <StyledCircleCheckIcon fill={colors.accentGreen} width={16} height={16} />
          <Label>{message}</Label>
        </Container>
      )}
    </Fragment>
  );
}

export default TaskModalNotification;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accentGreen10};
  border-radius: 3px;
  opacity: 1;
  display: flex;
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black05};
  border-radius: 3px;

  &.hidden {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 16px;
`;
