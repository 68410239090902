import { AFFILIATE, PHYSICIAN_TEAM } from 'constants/locationTypes';
import Episode from 'models/Episode';
import { GroupTypeOptions } from 'models/GroupType';
import LocationEpisode from 'models/LocationEpisode';
import User, { getDefaults as getUserModelDefaults, UserOptions } from 'models/User';
import InsightsFilter from 'models/userPreferences/InsightsFilter';
import PortfolioFilter from 'models/userPreferences/PortfolioFilter';

export const PERMISSIONS = {
  adminAttrView: 'adminAttrView',
  adminAttrCreate: 'adminAttrCreate',
  adminAttrEdit: 'adminAttrEdit',
  adminAttrValueView: 'adminAttrValueView',
  adminAttrValueCreate: 'adminAttrValueCreate',
  adminAttrValueEdit: 'adminAttrValueEdit',
  adminClassificationCreate: 'adminClassificationCreate',
  adminClassificationEdit: 'adminClassificationEdit',
  adminClassificationView: 'adminClassificationView',
  adminClientCreate: 'adminClientCreate',
  adminClientEdit: 'adminClientEdit',
  adminClientView: 'adminClientView',
  adminClientGroupTypeEdit: 'adminClientGroupTypeEdit',
  adminClientGroupTypeView: 'adminClientGroupTypeView',
  adminCollaborationEdit: 'adminCollaborationEdit',
  adminCollaborationView: 'adminCollaborationView',
  adminFeatureFlagEdit: 'adminFeatureFlagEdit',
  adminFeatureFlagView: 'adminFeatureFlagView',
  adminGroupCreate: 'adminGroupCreate',
  adminGroupDelete: 'adminGroupDelete',
  adminGroupEdit: 'adminGroupEdit',
  adminGroupView: 'adminGroupView',
  adminGroupTypeCreate: 'adminGroupTypeCreate',
  adminGroupTypeEdit: 'adminGroupTypeEdit',
  adminGroupTypeView: 'adminGroupTypeView',
  adminImportedPatientEdit: 'adminImportedPatientEdit',
  adminImportedPatientView: 'adminImportedPatientView',
  adminImportConfigurationCreate: 'adminImportConfigurationCreate',
  adminImportConfigurationEdit: 'adminImportConfigurationEdit',
  adminImportConfigurationView: 'adminImportConfigurationView',
  adminQuestionTemplateCreate: 'adminQuestionTemplateCreate',
  adminQuestionTemplateView: 'adminQuestionTemplateView',
  adminQuestionTemplateEdit: 'adminQuestionTemplateEdit',
  adminUserCreate: 'adminUserCreate',
  adminUserEdit: 'adminUserEdit',
  adminUserInvitationCreate: 'adminUserInvitationCreate',
  adminUserDelete: 'adminUserDelete',
  adminUserView: 'adminUserView',
  activityNoteCreate: 'activityNoteCreate',
  activityProgressUpdateCreate: 'activityProgressUpdateCreate',
  escalationCreate: 'escalationCreate',
  escalationEdit: 'escalationEdit',
  locationEpisodeDelete: 'locationEpisodeDelete',
  locationEpisodeUserEdit: 'locationEpisodeUserEdit',
  patientCreate: 'patientCreate',
  patientDelete: 'patientDelete',
  patientEdit: 'patientEdit',
  priorityNoteCreate: 'priorityNoteCreate',
  priorityNoteEdit: 'priorityNoteEdit',
  rehabStateAdmissionCreate: 'rehabStateAdmissionCreate',
  rehabStateDischargeCreate: 'rehabStateDischargeCreate',
  rehabStateDischargeEdit: 'rehabStateDischargeEdit',
  rehabStateEdit: 'rehabStateEdit',
  reviewAuthorizationEdit: 'reviewAuthorizationEdit',
  reviewProjectedDischargeEdit: 'reviewProjectedDischargeEdit',
  reviewServiceRefusalCreate: 'reviewServiceRefusalCreate',
  reviewServiceRefusalEdit: 'reviewServiceRefusalEdit',
  reviewAltcsApplicationEdit: 'reviewAltcsApplicationEdit',
};

export interface PatientContext {
  episode?: Episode;
  locationEpisode?: LocationEpisode;
}

export interface ProfileOptions extends UserOptions {
  selectedGroupIds: string[];
  allowedGroupIds: string[];
  canActAsManager: boolean;
  canActAsProvider: boolean;
  isAdmin: boolean;
  acceptedTermsOfService: boolean;
  enabledProviderTypes: GroupTypeOptions[];
}

function getDefaults(): ProfileOptions {
  return {
    ...getUserModelDefaults(),
    acceptedTermsOfService: false,
    allowedGroupIds: [],
    canActAsManager: false,
    canActAsProvider: false,
    isAdmin: false,
    selectedGroupIds: [],
  };
}

/**
 * @class Profile
 * @extends User
 * @classdesc Represents a user profile in the system
 * @property {string[]} selectedGroupIds - The profile's selected location ids
 * @property {string[]} allowedGroupIds - The profile's allowed location ids
 * @property {boolean} canActAsManager - Whether the user can act as a manager
 * @property {boolean} canActAsProvider - Whether the user can act as a provider
 * @property {boolean} isAdmin - Whether the profile is an admin
 * @property {boolean} acceptedTermsOfService - Whether the profile has accepted the terms of service
 * @param {Partial<ProfileOptions>} [options={}]
 * @example const profile = new Profile({ id: '123' });
 */

export default class Profile extends User {
  acceptedTermsOfService: boolean;
  allowedGroupIds: string[];
  canActAsManager: boolean;
  canActAsProvider: boolean;
  isAdmin: boolean;
  selectedGroupIds: string[];

  constructor(options?: Partial<ProfileOptions>) {
    const opts = { ...getDefaults(), ...(options ?? {}) };

    super(opts);

    this.selectedGroupIds = opts.selectedGroupIds;
    this.canActAsManager = opts.canActAsManager;
    this.canActAsProvider = opts.canActAsProvider;
    this.isAdmin = opts.isAdmin;
    this.acceptedTermsOfService = opts.acceptedTermsOfService;
    this.allowedGroupIds = opts.allowedGroupIds;
    this.groupType = opts.groupType;
  }

  get insightsPreference() {
    return this.preferences?.find(
      (pref): pref is InsightsFilter => pref.clientId === this.actingClient?.id && pref instanceof InsightsFilter
    );
  }

  get portfolioPreference() {
    return this.preferences?.find(
      (pref): pref is PortfolioFilter => pref.clientId === this.actingClient?.id && pref instanceof PortfolioFilter
    );
  }

  get isPhysicianTeamUser() {
    return this.groupType === PHYSICIAN_TEAM;
  }

  get clientType() {
    return this.actingClient.clientType;
  }

  get canCreatePatient() {
    if (this.isAffiliateUser) return false;

    return this.has(PERMISSIONS.patientCreate);
  }

  isOwnerUser(ownerClientId: string) {
    return this.actingClient.id === ownerClientId;
  }

  isManagerFor(episode?: Episode) {
    if (!episode) throw new Error('An episode is required to check if user is a manager for this patient context');

    const isManagerForEpisode = episode && this.canAccessGroup(episode.owner.id);
    const isAffiliateForEpisode =
      this.isAffiliateUser && episode?.affiliates.map((a) => a.clientId).includes(this.actingClient.id);

    return isManagerForEpisode || isAffiliateForEpisode;
  }

  isProviderFor(locationEpisode?: LocationEpisode) {
    if (!locationEpisode)
      throw new Error('A location episode is required to check if user is a provider for this patient context');

    return this.canAccessGroup(locationEpisode.groupId);
  }

  canAccessGroup(groupId: string) {
    return this.allowedGroupIds.includes(groupId);
  }

  get isAffiliateUser() {
    return this.clientType?.toLowerCase() === AFFILIATE;
  }

  get isPowerUser() {
    return this.canActAsManager && this.canActAsProvider;
  }

  has(permission: string, patientContext: PatientContext = {}) {
    return this.hasPermission(permission) && this.isAllowedForPatientContext(permission, patientContext);
  }

  hasAll(permissions: string[], patientContext: PatientContext = {}) {
    return permissions.every((permission) => this.has(permission, patientContext));
  }

  hasAny(permissions: string[], patientContext: PatientContext = {}) {
    return permissions.some((permission) => this.has(permission, patientContext));
  }

  hasFlag(flag: string) {
    return this.flags.includes(flag);
  }

  get canChangeClientScope() {
    return !!this.isAdmin || (this.client?.leafDescendants.length || 0) > 1;
  }

  private hasPermission(permission: string): boolean {
    if (!this.permissions) return false;

    return this[permission] || this.permissions[permission] || false;
  }

  private hasAllPermissions(permissions: string[]) {
    return permissions.every((permission) => this.hasPermission(permission));
  }

  private hasAnyPermission(permissions: string[]) {
    return permissions.some((permission) => this.hasPermission(permission));
  }

  private isAllowedForPatientContext = (permission: string, { episode, locationEpisode }: PatientContext) => {
    // Any permission not explicitly listed here will be allowed if the profile has been granted that permission
    switch (permission) {
      case PERMISSIONS.escalationEdit:
      case PERMISSIONS.locationEpisodeUserEdit:
      case PERMISSIONS.patientDelete:
      case PERMISSIONS.reviewAuthorizationEdit:
      case PERMISSIONS.reviewProjectedDischargeEdit:
      case PERMISSIONS.reviewServiceRefusalEdit:
        return this.isManagerFor(episode); // manager for the patient context
      case PERMISSIONS.activityProgressUpdateCreate:
      case PERMISSIONS.escalationCreate:
      case PERMISSIONS.priorityNoteEdit:
      case PERMISSIONS.reviewServiceRefusalCreate:
        return !this.isManagerFor(episode) && this.isProviderFor(locationEpisode); // provider for the patient context
      case PERMISSIONS.rehabStateAdmissionCreate:
      case PERMISSIONS.rehabStateDischargeCreate:
        return this.isManagerFor(episode) || this.isProviderFor(locationEpisode); // manager or provider for the patient context
      default:
        return true;
    }
  };
}
