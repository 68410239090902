import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import MiniSelect from 'components/shared/MiniSelect';
import { useEpisodeId } from 'hooks/useEpisodeId';
import { EscalationOptionTypes } from 'models/Escalation';
import Profile from 'models/Profile';
import {
  escalationsQueryKeys,
  showEscalation,
  useInfiniteEscalations,
  useInfinitePriorityNotes,
} from 'services/api/escalation';
import { useTaskModalActions, useTaskModalStore } from 'stores/taskModalStore';
import { colors } from 'styles/theme/colors';
import { H3, Label } from 'styles/typography';
import PriorityFlag from 'svg/PriorityFlag';
import WarnIcon from 'svg/WarnIcon';

import TaskModalBody from './TaskModalBody';
import TaskModalList from './TaskModalList';

type TaskModalType = {
  setShow: (b: boolean) => void;
  profile: Profile;
  show: boolean;
};

/**
- If episodeId is available, then show escalations for that episode only.
  - If no escalations are available for that episode, then show all escalations.
  - If escalations are available for that episode, then show only those escalations & provide an option to view all escalations.
- If episodeId is not available, then show all escalations.

Episode specific escalations cannot be shown again once "view all escalations" is clicked.
Close the modal and re-open it to view episode specific escalations.
 */
export default function TaskModal(props: TaskModalType) {
  const { setShow, profile, show } = props;

  const [viewType, setViewType] = useState<EscalationOptionTypes>(
    profile.canActAsManager ? EscalationOptionTypes.Escalation : EscalationOptionTypes.PriorityNote
  );

  const episodeId = useEpisodeId();
  const [showAllEscalations, setShowAllEscalations] = useState(!episodeId);
  const [selectedRecordId, setSelectedRecordId] = useState('');
  const [episodeHasData, setEpisodeHasData] = useState<boolean | null>(null);
  const patientName = useTaskModalStore((state) => state.currentPatientName);
  const viewedEpisodes = useTaskModalStore((state) => state.viewedEpisodes);
  const { addViewedEpisode } = useTaskModalActions();

  const showEpisodeEscalations = !showAllEscalations;
  const episodeViewMode = (episodeHasData == null || episodeHasData) && showEpisodeEscalations;

  const queryParams = {
    ...(episodeViewMode ? { episode: episodeId } : {}),
    isRespondable: true,
  };

  const escalationsQuery = useInfiniteEscalations(
    {
      select: (res) => {
        if (episodeHasData == null && showEpisodeEscalations) {
          setEpisodeHasData(episodeHasData || res.pages?.[0]?.meta?.totalRecords > 0);
        }

        return res;
      },
      enabled: profile.canActAsManager,
    },
    queryParams
  );

  const priorityNotesQuery = useInfinitePriorityNotes(
    {
      select: (res) => {
        if (episodeHasData == null && showEpisodeEscalations) {
          setEpisodeHasData(episodeHasData || res.pages?.[0]?.meta?.totalRecords > 0);
        }

        return res;
      },
      enabled: profile.canActAsProvider,
    },
    queryParams
  );

  const { data: selectedRecord, isLoading: loadingSelectedRecord } = useQuery({
    queryKey: escalationsQueryKeys.show(selectedRecordId),
    queryFn: () => showEscalation({ id: selectedRecordId, include: 'blob,fileDeletedByClient,group' }),
    enabled: !!selectedRecordId,
  });

  useEffect(() => {
    switch (viewType) {
      case EscalationOptionTypes.Escalation:
        setSelectedRecordId(escalationsQuery.data?.pages?.[0]?.data[0]?.id ?? '');
        break;
      case EscalationOptionTypes.PriorityNote:
        setSelectedRecordId(priorityNotesQuery.data?.pages?.[0]?.data[0]?.id ?? '');
        break;
      default:
        setSelectedRecordId('');
    }
  }, [escalationsQuery.data, priorityNotesQuery.data, viewType]);

  const recordTypeLabel = viewType === EscalationOptionTypes.Escalation ? 'Escalations' : 'Priorities';
  const escalationCount = escalationsQuery.data?.pages?.[0]?.meta.totalRecords || 0;
  const priorityNoteCount = priorityNotesQuery.data?.pages?.[0]?.meta.totalRecords || 0;

  const taskCount = viewType === EscalationOptionTypes.Escalation ? escalationCount : priorityNoteCount;
  const handleViewAllClick = () => {
    setShowAllEscalations(true);
    setSelectedRecordId('');
  };
  const handleOnClose = () => setShow(false);

  useEffect(() => {
    if (!profile.isAdmin && episodeId && episodeHasData && !viewedEpisodes.includes(episodeId)) {
      addViewedEpisode(episodeId);
      setShow(true);
    }
  }, [addViewedEpisode, episodeId, episodeHasData, profile.isAdmin, setShow, viewedEpisodes]);

  useEffect(() => {
    setShowAllEscalations(!episodeId);
    setSelectedRecordId('');
    setEpisodeHasData(null);
  }, [episodeId]);

  const miniSelectOptions = [
    { label: `Escalations (${escalationCount})`, value: EscalationOptionTypes.Escalation },
    { label: `Priorities (${priorityNoteCount})`, value: EscalationOptionTypes.PriorityNote },
  ];

  return (
    show && (
      <Backdrop>
        <StyledModal>
          <HeaderContainer>
            <>
              {viewType === EscalationOptionTypes.Escalation ? (
                <WarnIcon width={16} height={16} color={colors.accentRed} />
              ) : (
                <PriorityFlag width={16} height={16} color={colors.accentRed} />
              )}
              <H3>
                {episodeViewMode && patientName ? patientName + ' - ' : ''}
                {recordTypeLabel}
                {taskCount && !profile.isPowerUser ? ` (${taskCount})` : ''}
              </H3>

              {profile.isPowerUser && (
                <MiniSelect
                  options={miniSelectOptions}
                  onChange={(value) => setViewType(value.value)}
                  value={viewType}
                />
              )}
            </>
            {episodeViewMode && taskCount > 0 && (
              <VisibilityFilter onClick={handleViewAllClick}>View all {recordTypeLabel.toLowerCase()}</VisibilityFilter>
            )}
            <Spacer />
            <CloseButton onClick={handleOnClose}>&times;</CloseButton>
          </HeaderContainer>
          <ContentContainer data-cy='taskModalContentContainer'>
            <TaskModalList
              query={viewType === EscalationOptionTypes.Escalation ? escalationsQuery : priorityNotesQuery}
              selectedRecordId={selectedRecordId}
              setSelectedRecordId={setSelectedRecordId}
              recordTypeLabel={recordTypeLabel}
            />
            <TaskModalBody
              record={selectedRecord}
              loading={loadingSelectedRecord || (escalationsQuery.isLoading && !escalationsQuery.isFetchingNextPage)}
              profile={profile}
              closeModal={handleOnClose}
              viewType={viewType}
            />
          </ContentContainer>
        </StyledModal>
      </Backdrop>
    )
  );
}

const CloseButton = styled.div`
  color: var(--black-75);
  font-size: 24px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Spacer = styled.div`
  flex: 1;
`;

const StyledModal = styled.div<{ overflow?: string }>`
  flex-grow: 1;
  z-index: 2;

  height: 600px;
  background-color: ${colors.white};
  flex-basis: 480px;
  max-width: 960px;
  border: 1px solid ${colors.black25};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  margin-top: 80px;

  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.black50};
  overflow: auto;
`;

const VisibilityFilter = styled(Label)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin-left: 16px;
  cursor: pointer;
`;
