import { useMemo } from 'react';
import { isEqual } from 'lodash';

import { Dimensions } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MiniSelect from 'components/shared/MiniSelect';
import GroupType from 'models/GroupType';

type Props = {
  groupType: GroupType;
  onChange: (change) => void;
  additionalOptions?: Array<ViewByOption>;
  selectedDimensions: Dimensions[];
  excludeProviderGroupOption?: boolean;
  excludeProviderCompanyOption?: boolean;
};

type ViewByOption = {
  label: string;
  value: Dimensions[];
};

const ViewBySelect = ({
  groupType,
  onChange,
  additionalOptions,
  selectedDimensions,
  excludeProviderGroupOption = false,
  excludeProviderCompanyOption = false,
}: Props) => {
  const options = useMemo(() => {
    const providerGroupOption = {
      label: `${groupType?.displayName}`,
      value: ViewByValues.group,
    };

    const providerCompanyOption = {
      label: `${groupType?.displayName} Company`,
      value: ViewByValues.providerClient,
    };

    let options = [
      {
        label: 'Episode Type',
        value: ViewByValues.episodeClassification,
      },
      {
        label: 'Plan Type',
        value: ViewByValues.planTypeClassification,
      },
    ] as Array<ViewByOption>;

    options = [
      ...options,
      ...(excludeProviderGroupOption ? [] : [providerGroupOption]),
      ...(excludeProviderCompanyOption ? [] : [providerCompanyOption]),
      ...(additionalOptions ?? []),
    ];
    return options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [groupType?.displayName, excludeProviderGroupOption, excludeProviderCompanyOption, additionalOptions]);

  return (
    <MiniSelect
      options={options}
      onChange={onChange}
      value={options.find((option) => isEqual(option.value, selectedDimensions))?.value}
      title='View By'
    />
  );
};

export default ViewBySelect;
